import { useEffect, useMemo, useState } from 'react'
import { Form } from 'antd'
import cn from 'classnames'
import { ReactComponent as ChevronDown16 } from 'assets/svg/ChevronDown16.svg'
import { ReactComponent as ChevronUp16 } from 'assets/svg/ChevronUp16.svg'
import './styles.scss'

import { BTN_TXT, LABELS } from 'constants/txt'
import { Input } from 'components/Input'
import { Datepicker } from 'components/Datepicker'
import { Button, BUTTON_TYPES } from 'components/Button'
import { timelineDateFormat } from 'components/Timelines/constants'
import { IContractDetailsForm } from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/types'
import useContractDetailsForm from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/hooks/useContractDetailsForm'
import {
  twoDecimalParser,
  currencyFormatter,
  currencyParser
} from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import ContractDetailsDocuments from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsDocuments/ContractDetailsDocuments'
import AdditionalInfoPreview from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsAdditionalInfo/AdditionalInfoPreview'
import AdditionalInfoForm from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsAdditionalInfo/AdditionalInfoForm'
import { Popup } from '../../../../components/Popup'
import { useShowPopup } from '../../../../hooks/useShowPopup'
import { useContractDetailsContext } from '../../Providers/ContractDetailsContextProvider'
import usePopup from '../../../../hooks/usePopup'

const ContractDetailsForm = () => {
  const {
    form,
    isCreation,
    isFormRequired,
    hasFormChanges,
    handleFinishForm,
    handleCancelForm,
    handleChangeForm,
    finishDateFieldStatus,
    disabledDateSameOrBeforeStartDate,
    isChanged,
    setIsChanged
  } = useContractDetailsForm()
  const contractDetailsContext = useContractDetailsContext()
  const discardChangesPopup = usePopup()
  const { setActiveTab } = contractDetailsContext.actions
  const [firstLoad, setFirstLoad] = useState(false)
  const [showPopup, confirmNavigation, cancelNavigation] =
    useShowPopup(isChanged)

  useEffect(() => {
    if (!showPopup) setActiveTab('general')
  }, [showPopup])

  const StartDateField = useMemo(
    () => (
      <Datepicker
        propsItem={{
          name: 'start_date',
          label: LABELS.START_DATE,
          rules: [
            {
              required: isFormRequired,
              message: ''
            }
          ]
        }}
        propsDate={{
          format: timelineDateFormat,
          onChange: handleChangeForm
        }}
      />
    ),
    [handleChangeForm, isFormRequired]
  )

  const FinishDateField = useMemo(
    () => (
      <Datepicker
        propsItem={{
          name: 'finish_date',
          label: LABELS.FINISH_DATE,
          rules: [
            {
              required: isFormRequired,
              message: ''
            }
          ]
        }}
        propsDate={{
          format: timelineDateFormat,
          disabledDate: disabledDateSameOrBeforeStartDate,
          status: finishDateFieldStatus,
          onChange: handleChangeForm
        }}
      />
    ),
    [
      disabledDateSameOrBeforeStartDate,
      finishDateFieldStatus,
      handleChangeForm,
      isFormRequired
    ]
  )

  const AdminFeeField = useMemo(
    () => (
      <Input.Number
        className="spinner-input-number"
        propsItem={{
          name: 'admin_fee',
          label: LABELS.ADMIN_FEE,
          rules: [
            {
              required: isFormRequired,
              message: ''
            }
          ]
        }}
        propsInputNumber={{
          controls: { downIcon: <ChevronDown16 />, upIcon: <ChevronUp16 /> },
          min: '0',
          max: '3',
          parser: twoDecimalParser,
          placeholder: '0',
          onChange: handleChangeForm
        }}
      />
    ),
    [handleChangeForm, isFormRequired]
  )

  const ExpectedSpendField = useMemo(
    () => (
      <Input.Number
        propsItem={{
          name: 'expected_spend',
          label: LABELS.EXPECTED_SPEND,
          rules: [
            {
              required: isFormRequired,
              message: ''
            }
          ]
        }}
        propsInputNumber={{
          min: '0',
          maxLength: 64,
          controls: false,
          formatter: currencyFormatter,
          parser: currencyParser
        }}
      />
    ),
    [isFormRequired]
  )

  const cancelDiscardChanges = () => {
    discardChangesPopup.actions.close()
  }

  const submitCancellation = () => {
    handleCancelForm()
    discardChangesPopup.actions.close()
    setIsChanged(false)
  }

  const ExpectedSavingsField = useMemo(
    () => (
      <Input.Number
        propsItem={{
          name: 'expected_savings',
          label: LABELS.EXPECTED_SAVINGS,
          rules: [
            {
              required: isFormRequired,
              message: ''
            }
          ]
        }}
        propsInputNumber={{
          min: '0',
          maxLength: 64,
          controls: false,
          formatter: currencyFormatter,
          parser: currencyParser
        }}
      />
    ),
    [isFormRequired]
  )

  const FormActions = useMemo(
    () => (
      <div className="contract-details-form__actions">
        <Button onClick={discardChangesPopup.actions.open} upperCase>
          {BTN_TXT.CANCEL}
        </Button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          form="contract-details-form"
          htmlType="submit"
          upperCase
        >
          {isCreation ? BTN_TXT.CREATE : BTN_TXT.SAVE}
        </Button>
      </div>
    ),
    [handleCancelForm, isCreation]
  )

  return (
    <>
      <Form<IContractDetailsForm>
        id="contract-details-form"
        form={form}
        onChange={handleChangeForm}
        onFinish={handleFinishForm}
        onFieldsChange={() => {
          if (!firstLoad) setFirstLoad(true)
          else setIsChanged(true)
        }}
        className={cn({ 'contract-details-form--creation': isCreation })}
      >
        <div className="contract-details-form__grid-row">
          <div className="contract-details-form__grid-column">
            <div className="contract-details-form__grid-row two-columns">
              {StartDateField}
              {FinishDateField}
            </div>
            <div className="admin-fee-field">{AdminFeeField}</div>
            <div className="contract-details-form__grid-row two-columns">
              {ExpectedSpendField}
              {ExpectedSavingsField}
            </div>
          </div>
          {isCreation ? (
            <AdditionalInfoForm form={form} />
          ) : (
            <AdditionalInfoPreview />
          )}
        </div>
        {isCreation || <ContractDetailsDocuments />}
        {(hasFormChanges || isCreation) && FormActions}
      </Form>
      <Popup.Discard
        visible={showPopup ? showPopup : discardChangesPopup.state.visible}
        onCancel={showPopup ? cancelNavigation : cancelDiscardChanges}
        onSubmit={showPopup ? confirmNavigation : submitCancellation}
      />
    </>
  )
}

export default ContractDetailsForm
