import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Dropdown } from 'components/Dropdown'
import { Sorter } from 'components/Sorter/Sorter'
import {
  COMMONS_ROLES_OPTIONS,
  COMMUNITY_ROLES_OPTIONS,
  ROLES_NAMES,
  USER_STATUSES_OPTIONS,
  VENDOR_ROLES_OPTIONS
} from 'constants/common'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import {
  TContactFacilityUsersFiltersFunc,
  TContractFacilityUsersData,
  TContractFacilityUsersFilters,
  TContractSFacilityUsersColumnsFunc
} from './types'
import { fetchHealthSystemsList } from '../../HealthSystems/api'
import { CellWithSubtitle } from '../../../components/CellWithSubtitle/CellWithSubtitle'
import {
  Status,
  USER_STATUS_TYPES_TO_CLASSNAME
} from '../../../components/Status'

export const columns = ({
  applyFilters,
  filters,
  community
}: TContractSFacilityUsersColumnsFunc): ColumnsType<TContractFacilityUsersData> => {
  const _columns: ColumnsType<TContractFacilityUsersData> = [
    {
      dataIndex: 'first_name',
      key: 'first_name',
      width: '195px',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TContractFacilityUsersData>
          dataIndex="first_name"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          User name
        </Sorter>
      ),
      render: (_, { first_name, last_name }) => `${first_name} ${last_name}`,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'role',
      key: 'role',
      width: '195px',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TContractFacilityUsersData>
          dataIndex="role"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Role
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TContractFacilityUsersFilters,
          TContactFacilityUsersFiltersFunc,
          unknown
        >
          items={[
            ...COMMUNITY_ROLES_OPTIONS,
            ...COMMONS_ROLES_OPTIONS,
            ...VENDOR_ROLES_OPTIONS.map((role) => {
              return {
                ...role,
                label: `Vendor ${role.label}`
              }
            })
          ]}
          applyFilters={applyFilters}
          appliedFilters={filters}
          field="roles"
          {...props}
        />
      ),
      filterIcon: !!filters?.roles?.length ? <FilterApplied16 /> : <Filter16 />,
      filterSearch: true,
      render: (text) => ROLES_NAMES[text],
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'status',
      key: 'status',
      width: 180,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TContractFacilityUsersData>
          dataIndex="status"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Status
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TContractFacilityUsersFilters,
          TContactFacilityUsersFiltersFunc,
          unknown
        >
          items={USER_STATUSES_OPTIONS}
          applyFilters={applyFilters}
          appliedFilters={filters}
          field="statuses"
          {...props}
        />
      ),
      render: (_, { status }) => (
        <Status.Default className={USER_STATUS_TYPES_TO_CLASSNAME[status]}>
          {status}
        </Status.Default>
      ),
      filterIcon: !!filters?.statuses?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'email',
      key: 'email',
      width: '195px',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TContractFacilityUsersData>
          dataIndex="email"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Email
        </Sorter>
      ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'phone_number',
      key: 'phone_number',
      width: '195px',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TContractFacilityUsersData>
          dataIndex="phone_number"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Phone #
        </Sorter>
      ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'health_system',
      key: 'health_system',
      width: '195px',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TContractFacilityUsersData>
          dataIndex="health_system"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Health system
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TContractFacilityUsersFilters,
          TContactFacilityUsersFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={() => fetchHealthSystemsList(community)}
          field="health_systems"
          asyncSearch
          {...props}
        />
      ),
      render: (_, { health_system }) => (
        <CellWithSubtitle title={health_system} />
      ),
      filterIcon: !!filters?.health_systems?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'hospital',
      key: 'hospital',
      width: '195px',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TContractFacilityUsersData>
          dataIndex="hospital"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Hospital
        </Sorter>
      ),
      render: (_, { hospital }) => <CellWithSubtitle title={hospital} />,
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    }
  ]
  return _columns
}
